var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "" } },
    [
      _c("v-card-title", { staticClass: "mb-4" }, [
        _c("span", { staticClass: "secondary--text font-weight-bold" }, [
          _vm._v("Add New Snapshot"),
        ]),
      ]),
      _c(
        "v-card-text",
        [
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c("h4", { staticClass: "secondary--text" }, [
                _vm._v("Name of the snapshot"),
              ]),
              _c("v-text-field", {
                attrs: {
                  disabled: _vm.snapshotCreationLoading,
                  label: "Provide a snapshot name here",
                  rules: [_vm.rules.nonEmpty],
                  required: "",
                  outlined: "",
                },
                model: {
                  value: _vm.snapshotName,
                  callback: function ($$v) {
                    _vm.snapshotName = $$v
                  },
                  expression: "snapshotName",
                },
              }),
              _c("h4", { staticClass: "secondary--text mt-4" }, [
                _vm._v("Description of recently completed work phase"),
              ]),
              _c("v-textarea", {
                attrs: {
                  label: "Provide a description of your snapshot",
                  rows: "4",
                  "auto-grow": "",
                  rules: [_vm.rules.nonEmpty],
                  required: "",
                  disabled: _vm.snapshotCreationLoading,
                  outlined: "",
                },
                model: {
                  value: _vm.snapshotDescription,
                  callback: function ($$v) {
                    _vm.snapshotDescription = $$v
                  },
                  expression: "snapshotDescription",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-card-actions", { staticClass: "mt-4" }, [
        _c(
          "div",
          {
            staticClass: "d-flex align-center flex-column justify-center w-100",
          },
          [
            _c(
              "div",
              [
                _c(
                  "v-btn",
                  {
                    staticClass: "mr-1",
                    attrs: {
                      loading: _vm.snapshotCreationLoading,
                      disabled: !_vm.valid || _vm.snapshotCreationLoading,
                      color: "primary",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.addNewSnapshot()
                      },
                    },
                  },
                  [
                    _c("v-icon", { attrs: { small: "" } }, [_vm._v("add")]),
                    _vm._v(" Add Snapshot "),
                  ],
                  1
                ),
                _c(
                  "v-btn",
                  {
                    attrs: {
                      disabled: _vm.snapshotCreationLoading,
                      color: "primary",
                      outlined: "",
                      text: "",
                      to: {
                        name: "snapshot-overview",
                        params: {
                          oid: _vm.$route.params.oid,
                          sid: _vm.$route.params.sid,
                          iid: _vm.$route.params.iid,
                          snid: _vm.$route.params.snid,
                        },
                      },
                    },
                  },
                  [_vm._v(" cancel ")]
                ),
              ],
              1
            ),
            _vm.snapshotCreationError
              ? _c(
                  "div",
                  { staticClass: "mt-5" },
                  [
                    _c(
                      "v-alert",
                      {
                        attrs: {
                          color: "error",
                          icon: "warning",
                          text: "",
                          "max-width": "500",
                        },
                      },
                      [
                        _c("div", { staticClass: "d-flex flex-column" }, [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Snapshot creation error"),
                          ]),
                          _vm._v(
                            " " + _vm._s(_vm.snapshotCreationErrorContent) + " "
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ]
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }